@import 'variables/colors';
@import 'variables/layout';

/* stylelint-disable */
@mixin base-text($font-size, $line-height) {
  color: var(--primary-text-color);
  font-size: $font-size;
  line-height: $line-height;
}

.title {
  font-weight: 700;
  @include base-text(60px, 72px);
}

.title-section {
  font-weight: 600;
  @include base-text(32px, 38px);
}

.subtitle {
  font-weight: 600;
  @include base-text(18px, 22px);
}

.base-text {
  font-weight: 400;
  @include base-text(15px, 23px);
}

.caption-text {
  @include base-text(12px, 20px);
}

.fw-bold {
  font-weight: 700;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-normal {
  font-weight: 400;
}

.fw-thin {
  font-weight: 300;
}

.primary-text-color {
  color: var(--primary-color);
}

.white-text {
  color: $white;
}

.text-error {
  color: $red;
}

.text-success {
  color: $green;
}

.text-center {
  text-align: center;
}

.uppercase-text {
  text-transform: uppercase;
}


@for $i from 1 through 6 {
  .truncate-text-#{$i}-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
  }
}

@media screen and (max-width: $break-point-md) {
  .title {
    font-size: 44px;
    line-height: 48px;
  }
}

@media screen and (max-width: $break-point-sm) {
  .title {
    font-size: 32px;
    line-height: 38px;
  }

  .title-section {
    font-size: 28px;
    line-height: 34px;
  }
}
