// App variables
$app-header-height: 95px;
$app-footer-height: 155px;
$course-aside-width: 450px;

// Course item
$course-item-width: 310px;
$course-item-height: 420px;
$course-banner-height: 175px;

// Course highlight
$course-item-highlight-showcase-height: 100vh;
$course-item-highlight-showcase-width: 100vw;

// Posters
$course-poster-height: 310px;
$course-poster-width: $course-poster-height * 1080px / 1920px;
$course-item-highlight-height: 442px;
$course-item-highlight-width: $course-poster-width;
$course-grid-width: 85vw;

// Icons sizes
$icon-size-medium: 32px;
$icon-size-small: 24px;
$icon-size-x-small: 16px;
