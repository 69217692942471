@import 'variables/colors';
@import 'variables/layout';
@import 'variables/sizes';

.container {
  background-color: var(--primary-color);
  box-shadow: 0 8px 20px 0 rgba($black, 0.14);
  height: $app-header-height;
  left: 0;
  position: absolute;
  right: 0;
  top: $app-header-height;
  transform: skewX(80deg) scaleY(0);
  transform-origin: top;
  transition: transform $transition-duration $transition-function;

  &.open {
    transform: skewX(0) scaleY(1);
  }

  input {
    background-color: transparent;
    height: 100%;
    padding: 25px 150px;

    /* stylelint-disable */
    &::-webkit-input-placeholder {
      color: $white;
      font-weight: 300;
    }
    &:-moz-placeholder {
      color: $white;
      font-weight: 300;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: $white;
      font-weight: 300;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $white;
      font-weight: 300;
    }
    /* stylelint-enable */
  }
}

.icon,
.icon-search {
  cursor: pointer;
  height: $icon-size-small;
  position: absolute;
  right: 110px;
  top: 36px;
  width: $icon-size-small;

  path {
    fill: $white;
    transition: fill $transition-duration $transition-function;
  }

  &:hover path {
    fill: rgba($black, 0.85);
  }
}

.icon-search {
  height: $icon-size-medium;
  left: 110px;
  top: 32px;
  width: $icon-size-medium;
}

@media only screen and (max-width: $break-point-md) {
  .container input {
    padding: 25px 40px;
  }

  .icon {
    right: 40px;
  }

  .icon-search {
    left: 40px;
  }
}

@media only screen and (max-width: $break-point-sm) {
  .container {
    display: none;
  }
}
