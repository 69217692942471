@import 'variables/colors';
@import 'variables/layout';

.item {
  height: 48px;
  letter-spacing: 1px;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.link {
  padding: 0 12px;
  text-transform: uppercase;

  &::after {
    background-color: var(--primary-color);
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    right: 0;
    transform: scale(0);
    transition: transform $transition-duration $transition-function;
  }

  &:hover::after {
    transform: scale(1);
  }

  &:active,
  &:focus {
    color: $white;
  }

  &.current {
    font-weight: 600;

    &::after {
      transform: scale(1);
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .container {
    flex-direction: column;
    width: 100%;
  }

  .item {
    border-bottom: 1px solid $white;
    margin: 0;
    padding: 30px 15px;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }
  }

  .link {
    &::after {
      display: none;
    }

    &:hover,
    &:active {
      color: var(--text-primary-color);
    }
  }
}
