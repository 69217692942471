@import 'variables/colors';
@import 'variables/sizes';
@import 'variables/layout';

.container {
  background-color: $seashell;
  min-height: $app-footer-height;
  padding: 10px 20px;
}

.app-logo {
  max-width: 230px;
  width: 100%;
}

.item {
  flex-grow: 0.5;

  &.middle {
    flex: 5;
  }

  &.last {
    flex: 2;
  }
}

.link-item {
  height: 100%;
  margin: 0;
}

.link {
  margin-left: 5px;
  margin-right: 5px;

  svg {
    height: 28px;
    width: 28px;

    path {
      fill: var(--primary-color);
      transition: fill $quick-transition-duration $transition-function;
    }
  }

  &:hover,
  &:active {
    svg path {
      fill: rgba(var(--primary-color-rgb), 0.7);
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .container {
    flex-direction: column;
    padding: 30px 20px 40px;
  }

  .item:nth-child(2) {
    margin: 24px 0;
  }
}
