@import 'variables/colors';

.unlabeled-field {
  margin-top: 30px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.input-error {
  border: 1px solid $red;
}

.hidden {
  visibility: hidden;
}

.hidden-scrollbar {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    /* Chrome, Safari and Opera */
    display: none;
  }
}

// Toastify classes
/* stylelint-disable */
.Toastify__toast--success {
  background-color: $white;
  color: var(--primary-text-color);

  .Toastify__progress-bar {
    background-color: $green;
  }
}

.Toastify__close-button > svg {
  fill: var(--primary-text-color);
}

.Toastify__toast--error {
  background-color: $white;
  color: var(--primary-text-color);

  .Toastify__progress-bar {
    background-color: $red;
  }
}
/* stylelint-enable */

.pincode-input-container {
  margin: 40px auto;
}

.pincode-input-text {
  border-color: $tundora;
  border-radius: 5px;
  color: $black;
  font-size: 18px;
  font-weight: 700;
  /* stylelint-disable-next-line declaration-no-important */
  height: 54px !important;
  /* stylelint-disable-next-line declaration-no-important */
  width: 54px !important;
}

.react-international-phone-input-container {
  position: relative;
  width: 100%;

  .react-international-phone-country-selector-button {
    border: 1px solid $tundora;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 100%;

    &:focus {
      border-color: var(--form-focus-color, var(--primary-color));
      box-shadow: 0 0 6px 0 rgba(var(--form-focus-color-shadow, var(--primary-color-rgb)), 0.44);
    }
  }

  .react-international-phone-input {
    border: 1px solid $tundora;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: var(--primary-text-color);
    flex: 1;
    font-size: 18px;
    height: 60px;
    line-height: 22px;
    padding: 0 12px;

    &:focus {
      border-color: var(--form-focus-color, var(--primary-color));
      box-shadow: 0 0 6px 0 rgba(var(--form-focus-color-shadow, var(--primary-color-rgb)), 0.44);
    }
  }
}
