$white: #FFF;
$black: #000;
$red: #F00;
$gray: #CCC;
$green: #009E5B;

// Named colors
$seashell: #F1F1F1;
$hawkes-blue: #E4F1FD;
$alto: #E0E0E0;
$emperor: #555;
$loafer: #F8FAF0;
$tundora: #464646;
$alabaster: #FAFAFA;
