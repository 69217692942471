@import 'variables/colors';
@import 'variables/sizes';
@import 'variables/layout';

.container {
  background-color: none;
  height: $app-header-height;
  justify-content: space-evenly;
  left: 0;
  padding: 0 20px;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color $quick-transition-duration $transition-function;
  z-index: $z-index-high;

  &.default {
    background-color: $white;
  }

  &.translucent {
    background-color: transparent;
    border-bottom: none;
  }
}

.app-logo {
  max-width: 100%;
  width: 230px;
}

.button {
  padding: 0 20px;
}

.btn-user {
  min-width: 60px;
}

.avatar {
  border-radius: 4px;
  cursor: pointer;
  height: 60px;
  object-fit: cover;
  object-position: top;
  width: 60px;
}

.text {
  &.default {
    color: var(--primary-text-color);
  }

  &.translucent {
    color: $white;
  }
}

.btn-container {
  position: relative;
}

.btn-line {
  background-color: $tundora;
  height: 2px;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 12px;

  &::before,
  &::after {
    background-color: inherit;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 6px;
  }

  &::after {
    top: 12px;
  }
}

.collapse-btn {
  background-color: $white;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  display: none;
  height: 40px;
  position: relative;
  transition: background-color $quick-transition-duration $transition-function;
  width: 40px;

  &:hover,
  &:active {
    background-color: rgba(var(--primary-color-rgb), 0.3);
  }
}

@media only screen and (max-width: $break-point-sm) {
  .container {
    align-items: center;
    padding-left: 0;

    &.default,
    &.translucent {
      background-color: $white;
      border-bottom: none;
    }
  }

  .app-logo {
    width: 175px;
  }

  .text.translucent {
    color: var(--primary-text-color);
  }

  .sm-hide,
  .btn-container {
    display: none;
  }

  .collapse-btn {
    display: flex;
  }
}
