@import 'variables/colors';
@import 'variables/layout';

.container {
  background-color: var(--primary-color);
  box-shadow: 0 8px 14px 0 rgba($black, 0.14);
  left: 0;
  padding: 5px 15px;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform $quick-transition-duration $transition-function;

  &.open {
    transform: scaleY(1);
  }
}

.app-logo {
  max-width: 100%;
  width: 200px;
}

.close-btn img {
  height: 28px;
  width: 28px;
}

.input-search {
  position: relative;

  img {
    height: 24px;
    left: 18px;
    position: absolute;
    top: 18px;
    width: 24px;
  }

  input {
    background-color: transparent;
    border: 1px solid $white;
    border-radius: 5px;
    height: 60px;
    padding: 18px 18px 18px 50px;
    transition: border-color $quick-transition-duration $transition-function;
    width: 100%;

    /* stylelint-disable */
    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: $white;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $white;
      opacity: 1;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $white;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white;
    }
    /* stylelint-enable */

    &:focus {
      border-color: var(--primary-text-color);
    }
  }
}
