@import 'variables/colors';
@import 'variables/layout';

.btn {
  background-color: $gray;
  border-radius: 5px;
  color: rgba($white, 0.7);
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  min-width: 60px;
  text-align: center;
  text-transform: uppercase;
  transition: all $transition-duration $transition-function;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &:active {
    transform: scale(0.95);
  }
}

.btn-link-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: $white;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    color: $white;
  }
}

.btn-link-secondary {
  background-color: rgba($black, 0.08);
  color: rgba($black, 0.7);
  transition: background-color $transition-duration $transition-function;

  &:hover {
    background-color: rgba($black, 0.15);
  }
}

.btn-link-outline {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-text-color);
  transition: all $transition-duration $transition-function;

  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.15);
  }
}

.link {
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  transition: all $transition-duration $transition-function;

  &:hover,
  &:active {
    opacity: 0.85;
    text-decoration: underline;
  }
}
