@import 'variables/colors';
@import 'variables/layout';

.container {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 6px 14px 0 rgba($black, 0.34);
  max-width: 190px;
  position: absolute;
  right: 0;
  top: 100%;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform $quick-transition-duration $transition-function;
  width: 100%;

  &::before {
    border-bottom: 10px solid $white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: '';
    position: absolute;
    right: 22px;
    top: -10px;
  }

  &.open {
    transform: scaleY(1);
  }
}

.item {
  border-bottom: 1px solid $alto;

  &:last-child {
    border-bottom: none;
  }
}

.link {
  padding: 20px 30px;
  text-align: left;
  transition: color $quick-transition-duration $transition-function;
  width: 100%;

  &:hover {
    color: var(--primary-color);
  }
}
