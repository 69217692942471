// Z-index
$z-index-low: 1;
$z-index-middle: 2;
$z-index-high: 3;

// Transitions
$transition-duration: 290ms;
$transition-function: cubic-bezier(0.79, 0.01, 0.38, 0.99);
$quick-transition-duration: 200ms;

// General
$break-point-xs: 576px;
$break-point-sm: 768px;
$break-point-md: 992px;
$break-point-lg: 1024px;
// Max width in all the screens
$max-content-width: 1440px;

// Functions
/* stylelint-disable */
@function screensize($pixels, $size) {
  @return #{$pixels * 100 / $size}vw;
}
/* stylelint-enable */
