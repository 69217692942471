@import '~wolox-equalizer/equalizer';
@import '~react-toastify/dist/ReactToastify.min.css';
@import '~react-datepicker/dist/react-datepicker.min.css';
// @import '~react-international-phone/build/index.css';
@import '~react-multi-carousel/lib/styles.css';

@import 'layout';
@import 'margins';
@import 'components';
@import 'buttons';
@import 'badges';
@import 'fonts';

* {
  font-family: 'Open Sans', Oxygen, Ubuntu, sans-serif;
}

/* stylelint-disable */
@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

body {
  --accent-color: #FB7F06;
  --app-background: #FAFAFA;
  background-color: var(--app-background);
  --error-color: #F3491D;
  --primary-color: #9DB63B;
  --primary-color-rgb: #{hexToRGB(#9DB63B)};
  --primary-text-color: #1A1A1A;
  --secondary-text-color: $emperor;

  &[data-theme='BLAZE'] {
    --accent-color: #A12B2A;
    --form-focus-color: #1A1A1A;
    --form-focus-color-shadow: #{hexToRGB(#1A1A1A)};
    --primary-color: #E65300;
    --primary-color-rgb: #{hexToRGB(#E65300)};
    --secondary-text-color: #E0E0E0;
  }

  &[data-theme='TELEIOS'] {
    --accent-color: #49639B;
    --primary-color: #21B4FF;
    --primary-color-rgb: #{hexToRGB(#21B4FF)};
  }

  &[data-theme='AGATHOS'] {
    --accent-color: #3894ab;
    --primary-color: #515e7a;
    --primary-color-rgb: #{hexToRGB(#515e7a)};
  }

  &[data-theme='ANTORCHA'] {
    --accent-color: #3894ab;
    --primary-color: #515e7a;
    --primary-color-rgb: #{hexToRGB(#515e7a)};
  }
}
